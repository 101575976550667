import { useState, useEffect, useMemo, createContext, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { setExternalUserId, removeExternalUserId, sendTags } from "../../OneSignal";
import { getData, useErrorHandler, myProfileQuery, myRecentNodesQuery, myWorkstationQuery, getBuildingsQuery } from "../../Gql";
import { Utils } from "../../Utils";
import { ACL_ROLE } from "../../Constants";

export const UserContext = createContext();

export function UserProvider() {
  console.log("UserProvider");

  const { pathname } = useLocation();
  const skip = !pathname.startsWith("/main/");

  const [langLoaded, setLangLoaded] = useState(false);

  const profileInterval = 60000;
  const curUserData = useQuery(myProfileQuery, { pollInterval: profileInterval, skip });
  const curUserErr = useErrorHandler(curUserData);

  const curUser = useMemo(() => {
    const curUser = {
      data: getData(curUserData),
      error: curUserErr,
      loading: curUserData.loading,
      called: curUserData.called,
      refetch: curUserData.refetch,
      startPolling: () => {
        curUserData.startPolling(profileInterval);
      },
      stopPolling: curUserData.stopPolling,
      refetchAndPolling: () => {
        curUserData.stopPolling();
        curUserData.refetch();
        curUserData.startPolling(profileInterval);
      }
    };

    //update oneSignal external id
    if (!curUser.loading) {
      if (!curUser.error && curUser.data && curUser.data.id) {
        setExternalUserId(curUser.data.id, curUser.data.hash4OneSignal);
        sendTags({
          company: curUser.data.tenant.name.replace(/\s+/g, "_").toLowerCase(),
          role: curUser.data.aclRoles.join().toLowerCase()
        });
      } else {
        removeExternalUserId();
      }
    }

    //init lang
    if (!langLoaded && curUser.data) {
      Utils.saveLangCode(curUser.data.language);
      setLangLoaded(true);
    }

    return curUser;
  }, [curUserData, curUserErr]);

  const recentNodesInterval = 60000;
  const recNodeData = useQuery(myRecentNodesQuery, { pollInterval: recentNodesInterval, skip });
  const recNodeErr = useErrorHandler(recNodeData);
  const recentNodes = useMemo(() => {
    const user = getData(recNodeData);
    return {
      data: user ? user.recentUsedNodes : user,
      error: recNodeErr,
      loading: recNodeData.loading,
      called: recNodeData.called,
      refetch: recNodeData.refetch,
      startPolling: () => {
        recNodeData.startPolling(recentNodesInterval);
      },
      stopPolling: recNodeData.stopPolling,
      refetchAndPolling: () => {
        recNodeData.stopPolling();
        recNodeData.refetch();
        recNodeData.startPolling(recentNodesInterval);
      }
    };
  }, [recNodeData, recNodeErr]);

  const wsInterval = 60000;
  const curWsData = useQuery(myWorkstationQuery, { pollInterval: wsInterval, skip });
  const curWsErr = useErrorHandler(curWsData);
  const curWs = useMemo(() => {
    return {
      data: getData(curWsData),
      error: curWsErr,
      loading: curWsData.loading,
      called: curWsData.called,
      refetch: curWsData.refetch,
      startPolling: () => {
        curWsData.startPolling(wsInterval);
      },
      stopPolling: curWsData.stopPolling,
      refetchAndPolling: () => {
        curWsData.stopPolling();
        curWsData.refetch();
        curWsData.startPolling(wsInterval);
      }
    };
  }, [curWsData, curWsErr]);

  const buildingInterval = 3600000;
  const buildingData = useQuery(getBuildingsQuery, { variables: { filter: { filterZoneByTenantIds: [curUser.data?.tenant?.id] } }, skip: skip || !curUser.data?.tenant?.id }); //default no polling, will reset FloorList dropdown
  const buildingErr = useErrorHandler(buildingData);
  const [zoneMap, buildings] = useMemo(() => {
    let buildings = getData(buildingData);
    let zoneMap = null;

    if (buildings) {
      const nameSort = Utils.getSorter();
      zoneMap = {};
      buildings = JSON.parse(JSON.stringify(buildings));
      buildings.sort(nameSort);
      buildings.forEach(b => {
        b.zones.sort(nameSort);
        b.zones.forEach(z => (z.buildingName = b.name));
        Utils.arrayToMap(b.zones, "id", zoneMap);
      });
    }

    return [
      {
        data: zoneMap,
        error: buildingErr,
        loading: buildingData.loading,
        called: buildingData.called,
        refetch: buildingData.refetch,
        startPolling: () => {
          buildingData.startPolling(buildingInterval);
        },
        stopPolling: buildingData.stopPolling
      },
      {
        data: buildings,
        error: buildingErr,
        loading: buildingData.loading,
        called: buildingData.called,
        refetch: buildingData.refetch,
        startPolling: () => {
          buildingData.startPolling(buildingInterval);
        },
        stopPolling: buildingData.stopPolling
      }
    ];
  }, [buildingData, buildingErr]);

  useEffect(() => {
    if (pathname.startsWith("/main/head/control")) {
      curWs.refetchAndPolling();
      recentNodes.refetchAndPolling();
    } else {
      curWs.stopPolling();
      recentNodes.stopPolling();
    }
  }, [pathname]);

  return (
    <UserContext.Provider value={{ userRes: curUser, wsRes: curWs, recentNodesRes: recentNodes, buildingsRes: buildings, zoneMapRes: zoneMap }}>
      <Outlet />
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
