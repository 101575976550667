import { useMemo } from "react";
import { ApolloClient, InMemoryCache, createHttpLink, gql, useQuery, useMutation } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { v4 } from "uuid";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { useSimpleModal } from "./components/modalContext/SimpleModalContext";
import { URL, LIST_TYPE } from "./Constants";
import { Utils } from "./Utils";

const frag = {};
frag.simpleProfile = gql`
  fragment SimpleProfile on Profile {
    id
    email
    firstName
    lastName
  }
`;
frag.simpleDepartment = gql`
  fragment SimpleDepartment on Department {
    id
    name
  }
`;
frag.basicProfile = gql`
  fragment BasicProfile on Profile {
    ...SimpleProfile
    aclRoles
    departmentId
    department {
      ...SimpleDepartment
    }
    inviteStatusV2
    invitedLastTime
    invitedExpiredTimestamp
    invitedExpired
    accessCard
    recentZoneId
    managedZones {
      zoneId
    }
  }
  ${frag.simpleProfile}
  ${frag.simpleDepartment}
`;
frag.fullProfile = gql`
  fragment FullProfile on Profile {
    ...BasicProfile
    hash4OneSignal
    passwordExpired
    language
    accessInsight
    notificationPreference {
      workstationCloseAlert
      frequentRequestAdminAlert
      deviceOfflineAlert
    }
    displayConfig {
      insight
      dashboard
    }
    tenant {
      id
      name
      cityId
      passwordPolicy
      maxExtensionHours
      defaultExtensionMinutes
      enableFloorPlanSelection
      roomTemperatureVisibility
      widget {
        airconEnable
        lightingEnable
        greenScoreRatingEnable
        energyConsumptionEnable
        indoorAirQualityEnable
      }
      viewOthers
      enableTep
      enableEms
    }
  }
  ${frag.basicProfile}
`;

//TODOricky add description field
frag.displayScene = gql`
  fragment DisplayScene on Scene {
    id
    name
  }
`;
frag.fullScene = gql`
  fragment FullScene on Scene {
    ...DisplayScene
    zoneId
    configs {
      deviceId
    }
  }
  ${frag.displayScene}
`;

frag.workstationCount = gql`
  fragment WorkstationCount on Workstation {
    count {
      aircon
      light
      smartlight
      iaq
      button
      offline
    }
  }
`;
frag.workstationOwner = gql`
  fragment WorkstationOwner on Workstation {
    owners {
      id
      email
      firstName
      lastName
      departmentId
    }
  }
`;
frag.displayWorkstation = gql`
  fragment DisplayWorkstation on Workstation {
    id
    name
    isHotDesk
    locationPolygon
    roomTemperature
    zoneId
  }
`;
frag.statusWorkstation = gql`
  fragment StatusWorkstation on Workstation {
    ...DisplayWorkstation
    ...WorkstationCount
    scenes {
      ...DisplayScene
    }
    zoneId
    status
    isCooling
    isWarming
    dimmingLevel
    adminOnly
    sharable
    roomTemperature
    schedule {
      nextOnAt
      nextOffAt
      withIn
    }
  }
  ${frag.displayWorkstation}
  ${frag.workstationCount}
  ${frag.displayScene}
`;
frag.locationWorkstation = gql`
  fragment LocationWorkstation on Workstation {
    ...DisplayWorkstation
    ...WorkstationOwner
    deviceIds
    adminOnly
    sharable
    timetableId
    delegations {
      userId
    }
  }
  ${frag.displayWorkstation}
  ${frag.workstationOwner}
`;
frag.roomCount = gql`
  fragment RoomCount on Room {
    count {
      aircon
      light
      smartlight
      iaq
      button
      offline
    }
  }
`;
frag.displayRoom = gql`
  fragment DisplayRoom on Room {
    id
    name
    locationPolygon
    roomTemperature
  }
`;
frag.statusRoom = gql`
  fragment StatusRoom on Room {
    ...DisplayRoom
    ...RoomCount
    scenes {
      ...DisplayScene
    }
    zoneId
    status
    isCooling
    isWarming
    dimmingLevel
    adminOnly
    roomTemperature
    schedule {
      nextOnAt
      nextOffAt
      withIn
    }
  }
  ${frag.displayRoom}
  ${frag.roomCount}
  ${frag.displayScene}
`;
frag.locationRoom = gql`
  fragment LocationRoom on Room {
    ...DisplayRoom
    adminOnly
    deviceIds
    timetableId
  }
  ${frag.displayRoom}
`;
frag.deviceDetail = gql`
  fragment DeviceDetail on Device {
    id
    gatewayId
    serialId
    deviceType
    applicationType
    dimmable
    defaultFanSpeed
    online
    status
    location {
      id
      x
      y
    }
    defaultValue {
      defaultTemperature
      defaultDimming
    }
    thermoProfile {
      heatIndexInSchedule
      logicInSchedule
      heatIndexOutSchedule
      logicOutSchedule
    }
    co2Profile {
      threshold
      logic
    }
  }
`;
frag.displayZone = gql`
  fragment DisplayZone on Zone {
    id
    buildingId
    name
    floorPlan
    defaultConditionDuration
    alwaysOn
  }
`;
frag.zoneInfo = gql`
  fragment ZoneInfo on Zone {
    ...DisplayZone
    heatMode
    defaultCoolerDelta
    defaultWarmerDelta
    closeDelay
  }
  ${frag.displayZone}
`;
frag.zoneDevice = gql`
  fragment ZoneDevice on Zone {
    devices {
      ...DeviceDetail
    }
  }
  ${frag.deviceDetail}
`;
frag.dataRangeDetail = gql`
  fragment DataRangeDetail on DataRange {
    id
    timeOfDay
    range
    gracePeriod
  }
`;
frag.thresholdDetail = gql`
  fragment ThresholdDetail on Threshold {
    id
    objectType
    objectCategory
    objectId
    path
    max {
      value
      valid
    }
    min {
      value
      valid
    }
    aggregation
    status
    remark
  }
`;
frag.alertDetail = gql`
  fragment AlertDetail on Alert {
    id
    name
    alertType
    compareType
    checkFrequent
    thresholds {
      ...ThresholdDetail
    }
    dataRanges {
      ...DataRangeDetail
    }
    alertTouchPoints {
      id
      subscribers {
        id
        userId
        receivingMode
      }
    }
  }
  ${frag.thresholdDetail}
  ${frag.dataRangeDetail}
`;

export const logSignInMutation = gql`
  mutation recordSignInEvent {
    recordSignInEvent
  }
`;

export const getTenantsQuery = gql`
  query getTenants {
    getTenants {
      id
      name
    }
  }
`;
export const changeTenantMutation = gql`
  mutation updateTenantProfile($id: ID!) {
    updateTenantProfile(tenantId: $id)
  }
`;

export const getDisplayTimetablesQuery = gql`
  query getTimetables {
    getTimetables {
      id
      name
    }
  }
`;
export const getTimetableQuery = gql`
  query getTimetable($id: ID!) {
    getTimetable(id: $id) {
      id
      name
      timeslots {
        id
        weekday
        start {
          hour
          minute
          action
        }
        end {
          hour
          minute
          action
        }
      }
    }
  }
`;

export const createDelegationMutation = gql`
  mutation createDelegation($userId: ID, $nodeId: ID!) {
    createDelegation(userId: $userId, nodeId: $nodeId) {
      userId
      nodeId
    }
  }
`;

export const deleteDelegationMutation = gql`
  mutation deleteDelegation($userId: ID!, $nodeId: ID!) {
    deleteDelegation(userId: $userId, nodeId: $nodeId) {
      userId
      nodeId
    }
  }
`;

export const myWorkstationQuery = gql`
  query {
    myWorkstation {
      cityId
      delegations {
        userId
      }
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;
export const workstationQuery = gql`
  query getWorkstation($id: ID!) {
    getWorkstation(id: $id) {
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;
export const displayWorkstationQuery = gql`
  query getDisplayWorkstation($id: ID!) {
    getWorkstation(id: $id) {
      ...DisplayWorkstation
    }
  }
  ${frag.displayWorkstation}
`;
export const availableWorkstationsQuery = gql`
  query availableWorkstations($zoneId: ID) {
    availableWorkstations(zoneId: $zoneId) {
      ...DisplayWorkstation
    }
  }
  ${frag.displayWorkstation}
`;
export const controllableWorkstationsQuery = gql`
  query controllableWorkstations($zoneId: ID) {
    controllableWorkstations(zoneId: $zoneId) {
      ...DisplayWorkstation
      ...WorkstationOwner
    }
  }
  ${frag.displayWorkstation}
  ${frag.workstationOwner}
`; //...StatusWorkstation

export const turnOnWorkstationMutation = gql`
  mutation initiateWorkstation($id: ID, $duration: Int) {
    initiateWorkstation(id: $id, duration: $duration) {
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;
export const extendWorkstationMutation = gql`
  mutation extendWorkstation($id: ID, $duration: Int!) {
    extendWorkstation(id: $id, duration: $duration) {
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;
export const turnOffWorkstationMutation = gql`
  mutation closeWorkstation($id: ID!) {
    closeWorkstation(id: $id) {
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;
export const changeWorkstationConditionMutation = gql`
  mutation changeWorkstationCondition($id: ID!, $condition: NodeCondition!) {
    changeWorkstationCondition(id: $id, condition: $condition) {
      ...StatusWorkstation
      actionTimeLeft {
        action
        timeLeft
      }
    }
  }
  ${frag.statusWorkstation}
`;
export const selectWorkstationMutation = gql`
  mutation selectWorkstation($id: ID!) {
    selectWorkstation(id: $id) {
      ...StatusWorkstation
    }
  }
  ${frag.statusWorkstation}
`;

export const roomQuery = gql`
  query getRoom($id: ID!) {
    getRoom(id: $id) {
      ...StatusRoom
    }
  }
  ${frag.statusRoom}
`;
//query name is availableRooms, but the logic is like controllableWorkstations
export const controllableRoomsQuery = gql`
  query availableRooms($zoneId: ID) {
    availableRooms(zoneId: $zoneId) {
      ...DisplayRoom
    }
  }
  ${frag.displayRoom}
`; //...StatusRoom

export const turnOnRoomMutation = gql`
  mutation initiateRoom($id: ID!, $duration: Int) {
    initiateRoom(id: $id, duration: $duration) {
      ...StatusRoom
    }
  }
  ${frag.statusRoom}
`;
export const extendRoomMutation = gql`
  mutation extendRoom($id: ID!, $duration: Int!) {
    extendRoom(id: $id, duration: $duration) {
      ...StatusRoom
    }
  }
  ${frag.statusRoom}
`;
export const turnOffRoomMutation = gql`
  mutation closeRoom($id: ID!) {
    closeRoom(id: $id) {
      ...StatusRoom
    }
  }
  ${frag.statusRoom}
`;
export const changeRoomConditionMutation = gql`
  mutation changeRoomCondition($id: ID!, $condition: NodeCondition!) {
    changeRoomCondition(id: $id, condition: $condition) {
      ...StatusRoom
      actionTimeLeft {
        action
        timeLeft
      }
    }
  }
  ${frag.statusRoom}
`;

export const createWorkstationMutation = gql`
  mutation createWorkstation($zoneId: ID!, $workstation: CreateWorkstationInput!) {
    createWorkstation(zoneId: $zoneId, workstation: $workstation) {
      ...LocationWorkstation
    }
  }
  ${frag.locationWorkstation}
`;
export const createRoomMutation = gql`
  mutation createRoom($zoneId: ID!, $room: CreateRoomInput!) {
    createRoom(zoneId: $zoneId, room: $room) {
      ...LocationRoom
    }
  }
  ${frag.locationRoom}
`;
export const updateWorkstationMutation = gql`
  mutation updateWorkstation($id: ID!, $workstation: UpdateWorkstationInput!) {
    updateWorkstation(id: $id, workstation: $workstation) {
      ...LocationWorkstation
    }
  }
  ${frag.locationWorkstation}
`;
export const updateRoomMutation = gql`
  mutation updateRoom($id: ID!, $room: UpdateRoomInput!) {
    updateRoom(id: $id, room: $room) {
      ...LocationRoom
    }
  }
  ${frag.locationRoom}
`;
export const updateWorkstationLocationMutation = gql`
  mutation updateWorkstationLocation($id: ID!, $locationPolygon: String!) {
    updateWorkstationLocation(id: $id, locationPolygon: $locationPolygon) {
      ...LocationWorkstation
    }
  }
  ${frag.locationWorkstation}
`;
export const updateRoomLocationMutation = gql`
  mutation updateRoomLocation($id: ID!, $locationPolygon: String!) {
    updateRoomLocation(id: $id, locationPolygon: $locationPolygon) {
      ...LocationRoom
    }
  }
  ${frag.locationRoom}
`;
export const updateNodesMutation = gql`
  mutation bulkUpdateNode($nodeIds: [ID]!, $name: String, $owners: [ID], $delegations: [ID], $isHotDesk: Boolean, $adminOnly: Boolean, $timetableId: ID, $sharable: Boolean) {
    bulkUpdateNode(
      nodeIds: $nodeIds
      name: $name
      owners: $owners
      delegations: $delegations
      isHotDesk: $isHotDesk
      adminOnly: $adminOnly
      timetableId: $timetableId
      sharable: $sharable
    )
  }
`;

export const deleteWorkstationMutation = gql`
  mutation deleteWorkstation($id: ID!) {
    deleteWorkstation(id: $id)
  }
`;
export const deleteRoomMutation = gql`
  mutation deleteRoom($id: ID!) {
    deleteRoom(id: $id)
  }
`;

export const getBuildingsQuery = gql`
  query getBuildingsByTenant($filter: GetBuildingsByTenantFilter) {
    getBuildingsByTenant(filter: $filter) {
      id
      name
      zones {
        ...DisplayZone
      }
    }
  }
  ${frag.displayZone}
`;

export const getLocationZoneQuery = gql`
  query getZoneWithDevices($id: ID!) {
    getZone(id: $id) {
      ...ZoneInfo
      ...ZoneDevice
    }
  }
  ${frag.zoneInfo}
  ${frag.zoneDevice}
`;
export const getLocationNodesQuery = gql`
  query getZoneWithNodes($id: ID!) {
    getZone(id: $id) {
      id
      workstations {
        ...LocationWorkstation
      }
      rooms {
        ...LocationRoom
      }
    }
  }
  ${frag.locationWorkstation}
  ${frag.locationRoom}
`;

export const getGatewayQuery = gql`
  query getGateway($id: ID!) {
    getGateway(id: $id) {
      id
      serialId
    }
  }
`;

export const updateZoneMutation = gql`
  mutation updateZone($id: ID!, $zone: UpdateZoneInput!) {
    updateZone(id: $id, zone: $zone) {
      ...ZoneInfo
    }
  }
  ${frag.zoneInfo}
`;

export const toggleFloorMutation = gql`
  mutation toggleZoneDevices($id: ID!, $state: DeviceState) {
    toggleZoneDevices(id: $id, state: $state) {
      ...DisplayZone
    }
  }
  ${frag.displayZone}
`;

export const myProfileQuery = gql`
  query {
    myProfile {
      ...FullProfile
    }
  }
  ${frag.fullProfile}
`;
export const myRecentNodesQuery = gql`
  query {
    myProfile {
      id
      recentUsedNodes {
        lastUsedTime
        nodeId
        room {
          ...StatusRoom
        }
        workstation {
          ...StatusWorkstation
        }
      }
    }
  }
  ${frag.statusWorkstation}
  ${frag.statusRoom}
`;

export const getZoneAdminsByZoneQuery = gql`
  query queryZoneAdmin($zoneId: ID, $userId: ID) {
    queryZoneAdmin(zoneId: $zoneId, userId: $userId) {
      zoneId
      userId
    }
  }
`;
export const createZoneAdminMutation = gql`
  mutation createZoneAdmin($zoneId: ID!, $userId: ID!) {
    createZoneAdmin(zoneId: $zoneId, userId: $userId) {
      zoneId
      userId
    }
  }
`;
export const deleteZoneAdminMutation = gql`
  mutation deleteZoneAdmin($zoneId: ID!, $userId: ID!) {
    deleteZoneAdmin(zoneId: $zoneId, userId: $userId) {
      zoneId
      userId
    }
  }
`;

export const getProfilesOfTenantQuery = gql`
  query getProfilesOfTenant($limit: Int, $offset: Int, $searchKey: String, $aclRoles: [ACLRole]) {
    getProfilesOfTenant(limit: $limit, offset: $offset, searchKey: $searchKey, aclRoles: $aclRoles) {
      ...SimpleProfile
      recentZoneId
    }
  }
  ${frag.simpleProfile}
`;

export const getDepartmentsQuery = gql`
  query getDepartments($id: ID!) {
    getDepartments(tenantId: $id) {
      ...SimpleDepartment
    }
  }
  ${frag.simpleDepartment}
`;

export const getSetRoleOptionsQuery = gql`
  query getSetAclRoleOptions($page: String) {
    getSetAclRoleOptions(page: $page) {
      roles
      availableRoles
      required
    }
  }
`;
export const createTenantUser = gql`
  mutation createTenantUserV2($tenantId: ID!, $email: String!, $aclRoles: [ACLRole]!, $options: CreateTenantUserInput!, $invite: Boolean, $managedZoneIds: [ID]) {
    createTenantUserV2(tenantId: $tenantId, email: $email, aclRoles: $aclRoles, options: $options, invite: $invite, managedZoneIds: $managedZoneIds) {
      ...BasicProfile
    }
  }
  ${frag.basicProfile}
`;
export const queryProfilesQuery = gql`
  query queryProfiles($tenant: ID, $search: String, $limit: Int, $offset: Int, $orders: [QueryProfilesOrderInput]) {
    queryProfiles(input: { tenant: $tenant, search: $search, limit: $limit, offset: $offset, orders: $orders }) {
      ...BasicProfile
    }
  }
  ${frag.basicProfile}
`;
export const getAssignedWorkstationsQuery = gql`
  query getProfile($id: ID!) {
    getProfile(id: $id) {
      id
      assignedWorkstations {
        ...DisplayWorkstation
      }
    }
  }
  ${frag.displayWorkstation}
`;

export const getSimpleProfileQuerys = ids => {
  let tmp = "";
  if (ids?.length) {
    tmp = ids
      .map(
        (id, i) => `
      getProfile${i}: getProfile(id: "${id}") {
        ...SimpleProfile
      }
    `
      )
      .join("");
  }
  return gql`
    query getProfileByIds {
      ${tmp}
    }
    ${frag.simpleProfile}
  `;
};

export const createDepartmentMutation = gql`
  mutation createDepartment($tenantId: ID!, $name: String!) {
    createDepartment(tenantId: $tenantId, name: $name) {
      id
      name
    }
  }
`;
export const updateDepartmentMutation = gql`
  mutation updateDepartment($id: ID!, $name: String!) {
    updateDepartment(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const deleteDepartmentMutation = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id)
  }
`;

export const deleteProfile = gql`
  mutation deleteProfile($id: ID!) {
    deleteProfile(id: $id)
  }
`;
export const accountInviteUser = gql`
  mutation inviteUserV2($id: ID!) {
    inviteUserV2(id: $id) {
      ...BasicProfile
    }
  }
  ${frag.basicProfile}
`;

export const getHierarchyAssignmentsQuery = gql`
  query listHierarchyAssignments($userId: ID!, $nodeType: HierarchyNodeType) {
    listHierarchyAssignments(userId: $userId, nodeType: $nodeType) {
      userId
      nodeType
      nodeId
    }
  }
`;
export const createHierarchyAssignmentMutation = gql`
  mutation createHierarchyAssignment($userId: ID!, $nodeType: HierarchyNodeType!, $nodeId: ID!) {
    createHierarchyAssignment(userId: $userId, nodeType: $nodeType, nodeId: $nodeId)
  }
`;
export const removeHierarchyAssignmentMutation = gql`
  mutation removeHierarchyAssignment($userId: ID!, $nodeType: HierarchyNodeType!, $nodeId: ID!) {
    removeHierarchyAssignment(userId: $userId, nodeType: $nodeType, nodeId: $nodeId)
  }
`;
export const getHierarchyNameQuerys = nodes => {
  let tmp = "";
  if (nodes?.length) {
    tmp = nodes
      .map((n, i) => {
        if (n.nodeType === "N_POV") {
          return `
          getHierarchyName${i}: getHierarchyPov(id: "${n.nodeId}") {
            id
            name
          }
        `;
        } else if (n.nodeType === "N_ZONE") {
          return `
          getHierarchyName${i}: getHierarchyZone(id: "${n.nodeId}") {
            id
            name
          }
        `;
        } else if (n.nodeType === "N_SITE") {
          return `
          getHierarchyName${i}: getHierarchySite(id: "${n.nodeId}") {
            id
            name
          }
        `;
        } else if (n.nodeType === "N_DATAPOINT") {
          return `
          getHierarchyName${i}: getHierarchyDataPoint(id: "${n.nodeId}") {
            id
            name
          }
        `;
        } else {
          return "";
        }
      })
      .join("");
  }
  console.log("~~~~~~", tmp);
  return gql`
    query getHierarchyNames {
      ${tmp}
    }
  `;
};

export const weatherQuery = gql`
  query getCurrentWeather($cityId: ID!) {
    getCurrentWeather(cityId: $cityId) {
      country
      cityName
      temperature
      humidity
      icon
      lowestTemperature
      highestTemperature
    }
  }
`;

export const weatherForecastQuery = gql`
  query getForecast($cityId: ID!) {
    getForecast(cityId: $cityId) {
      lowestTemperature
      highestTemperature
      icon
      forecastDay
    }
  }
`;

export const environmentQuery = gql`
  query getEnvironmental($id: ID!, $filter: EnviromentalFilterType!) {
    getEnvironmental(id: $id, filter: $filter) {
      iaqReading {
        measurement
        avg
      }
      heatIndex {
        indoor
        outdoor
      }
      lux
    }
  }
`;
export const environmentWithRangeQuery = gql`
  query getEnvironmental($id: ID!, $filter: EnviromentalFilterType!) {
    getEnvironmental(id: $id, filter: $filter) {
      iaqReading {
        measurement
        avg
      }
      standard {
        matrix
        fair
        good
        excellent
      }
    }
  }
`;

export const summaryDataQuery = gql`
  query getSummaryData($povIds: [ID]!, $timePeriod: TimePeriodType!, $source: SourceType!, $timezoneOffsiteMinute: Int!, $metricType: MetricType, $pellet: SummaryDataPellet) {
    getSummaryData(povIds: $povIds, timePeriod: $timePeriod, source: $source, timezoneOffsiteMinute: $timezoneOffsiteMinute, metricType: $metricType, pellet: $pellet) {
      povId
      totalValue
      values {
        ts
        value
        number
      }
    }
  }
`;

export const tenantCityRankQuery = gql`
  query listRankTenant($id: ID!, $cityId: ID, $startTime: Int, $endTime: Int) {
    listRankTenant(tenantId: $id, cityId: $cityId, startTime: $startTime, endTime: $endTime) {
      level
    }
  }
`;
export const buildingCityRankQuery = gql`
  query listRankBuilding($id: ID!, $cityId: ID, $startTime: Int, $endTime: Int) {
    listRankBuilding(buildingId: $id, cityId: $cityId, startTime: $startTime, endTime: $endTime) {
      level
    }
  }
`;
export const zoneCityRankQuery = gql`
  query listRankZone($id: ID!, $cityId: ID, $startTime: Int, $endTime: Int) {
    listRankZone(zoneId: $id, cityId: $cityId, startTime: $startTime, endTime: $endTime) {
      level
    }
  }
`;

export const getActionSummaryQuery = gql`
  query getAnalyzerSummaryActions($id: ID!, $by: ByType!, $mode: AnalyzerSummaryMode!, $startTime: Int!, $endTime: Int!) {
    getAnalyzerSummaryActions(id: $id, by: $by, mode: $mode, startTime: $startTime, endTime: $endTime) {
      cool
      warm
      coolByMe
      warmByMe
    }
  }
`;
export const getTemperatureChartQuery = gql`
  query getAnalyzerSummaryAnyDays($id: ID, $by: SummaryTermperatureFilterType!, $startTime: Int!, $endTime: Int!) {
    getAnalyzerSummaryAnyDays(dimensionId: $id, dimension: $by, startTime: $startTime, endTime: $endTime) {
      maximum
      minimum
      average
      averageDay {
        value
        date
      }
    }
  }
`;
export const getLightingChartQuery = gql`
  query getSummaryLightAnyDays($id: ID!, $by: ByType!, $startTime: Int!, $endTime: Int) {
    getSummaryLightAnyDays(id: $id, by: $by, startTime: $startTime, endTime: $endTime) {
      average
      maximum
      minimum
      averageDay {
        value
        date
      }
    }
  }
`;
export const getEnergyChartQuery = gql`
  query kwhZone($id: ID!, $by: ByType!, $startTime: Int!, $endTime: Int!) {
    kwhZone(id: $id, by: $by, startTime: $startTime, endTime: $endTime) {
      summaryEnergy {
        value
        date
      }
    }
  }
`;

export const getWorkstationScenesQuery = gql`
  query getWorkstation($id: ID!) {
    getWorkstation(id: $id) {
      scenes {
        ...FullScene
      }
    }
  }
  ${frag.fullScene}
`;
export const getRoomScenesQuery = gql`
  query getRoom($id: ID!) {
    getRoom(id: $id) {
      scenes {
        ...FullScene
      }
    }
  }
  ${frag.fullScene}
`;
export const getTenantScenesQuery = gql`
  query getTenant($id: ID!) {
    getTenant(id: $id) {
      scenes {
        ...FullScene
      }
    }
  }
  ${frag.fullScene}
`;
export const activateSceneMutation = gql`
  mutation activateScene($id: ID!) {
    activateScene(id: $id) {
      ...DisplayScene
    }
  }
  ${frag.displayScene}
`;

export const get7DaysWorkstationHistoryQuery = gql`
  query getSetPointHistoryByWorkstation($id: ID!) {
    getSetPointHistoryByWorkstation(id: $id) {
      nodeActivitiesLog {
        action
        triggerBy
        timestamp
        object {
          profile {
            ...SimpleProfile
          }
        }
      }
    }
  }
  ${frag.simpleProfile}
`;

// export const getTemperatureLogQuery = gql`
//   query getTemperatureLog($input: GetTemperatureLogInput!) {
//     getTemperatureLog(input: $input) {
//       id
//       defaultTemperature
//       readings
//     }
//   }
// `;

export const updatePasswordMutation = gql`
  mutation updatePassword($password: String!) {
    updatePassword(password: $password)
  }
`;

export const updateProfileMutation = gql`
  mutation updateProfile($id: ID!, $profile: UpdateProfileInput!, $managedZoneIds: [ID]) {
    updateProfile(id: $id, profile: $profile, managedZoneIds: $managedZoneIds) {
      ...FullProfile
    }
  }
  ${frag.fullProfile}
`;

export const updateNotificationPreferenceMutation = gql`
  mutation updateNotificationPreference($workstationCloseAlert: Boolean, $frequentRequestAdminAlert: Boolean, $deviceOfflineAlert: Boolean) {
    updateNotificationPreference(workstationCloseAlert: $workstationCloseAlert, frequentRequestAdminAlert: $frequentRequestAdminAlert, deviceOfflineAlert: $deviceOfflineAlert) {
      workstationCloseAlert
      frequentRequestAdminAlert
      deviceOfflineAlert
    }
  }
`;

export const devicePropertyMutation = gql`
  mutation portalUpdateDevice($id: ID!, $device: PortalUpdateDeviceInput!, $zoneId: ID) {
    portalUpdateDevice(id: $id, device: $device, zoneId: $zoneId) {
      ...DeviceDetail
    }
  }
  ${frag.deviceDetail}
`;

export const getAlertsQuery = gql`
  query getsAlert($offset: Int, $limit: Int!, $tenantId: ID) {
    getsAlert(offset: $offset, limit: $limit, tenantId: $tenantId) {
      ...AlertDetail
    }
  }
  ${frag.alertDetail}
`;

export const queryAlertTouchPoint = gql`
  query queryAlertTouchPoint($alertId: ID!) {
    queryAlertTouchPoint(alertId: $alertId) {
      id
      alertId
      touchPointId
    }
  }
`;

export const subscriberNotification = gql`
  mutation subscriberNotification($userId: ID!, $alertTouchPointId: ID!, $receivingMode: ReceivingModes!) {
    subscriberNotification(userId: $userId, alertTouchPointId: $alertTouchPointId, receivingMode: $receivingMode) {
      id
    }
  }
`;
export const changeSubscriptionPreference = gql`
  mutation changeSubscriptionPreference($subscriberId: ID!, $receivingMode: ReceivingModes!) {
    changeSubscriptionPreference(subscriberId: $subscriberId, receivingMode: $receivingMode) {
      id
      userId
      alertTouchPointId
      receivingMode
      createdAt
    }
  }
`;

export const unsubscribeNotification = gql`
  mutation unsubscribeNotification($subscriberId: ID!) {
    unsubscribeNotification(subscriberId: $subscriberId)
  }
`;
export const getAlertLogsQuery = gql`
  query queryAlertLog($input: QueryAlertLogRequest) {
    queryAlertLog(input: $input) {
      id
      values
      occurrenceCount
      createdAt
      updatedAt
      alertSnapshot
      thresholdSnapshot
      comparativeValues
      triggerCondition
      path
    }
  }
`;
export const createAlertMutation = gql`
  mutation createAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      ...AlertDetail
    }
  }
  ${frag.alertDetail}
`;
export const updateAlertMutation = gql`
  mutation updateAlert($input: UpdateAlertInput!) {
    updateAlert(input: $input) {
      ...AlertDetail
    }
  }
  ${frag.alertDetail}
`;
export const deleteAlertMutation = gql`
  mutation deleteAlert($id: ID!) {
    deleteAlert(id: $id)
  }
`;
export const createThresholdMutation = gql`
  mutation createThreshold($input: CreateThresholdInput!) {
    createThreshold(input: $input) {
      ...ThresholdDetail
    }
  }
  ${frag.thresholdDetail}
`;
export const updateThresholdMutation = gql`
  mutation updateThreshold($input: UpdateThresholdInput!) {
    updateThreshold(input: $input) {
      ...ThresholdDetail
    }
  }
  ${frag.thresholdDetail}
`;
export const createDataRangeMutation = gql`
  mutation createDataRange($input: CreateDataRangeInput!) {
    createDataRange(input: $input) {
      ...DataRangeDetail
    }
  }
  ${frag.dataRangeDetail}
`;
export const updateDataRangeMutation = gql`
  mutation updateDataRange($input: UpdateDataRangeInput!) {
    updateDataRange(input: $input) {
      ...DataRangeDetail
    }
  }
  ${frag.dataRangeDetail}
`;

// for updating cache when a mutation is called
export function getCreateCacheOpt(queryToUpdate, variables, paginationEnabled, otherQuerys) {
  return {
    update: (cache, { data }) => {
      let queries = [{ query: queryToUpdate, variables, hasPagination: paginationEnabled }];
      if (otherQuerys) {
        queries.push({ query: otherQuerys });
      }
      queries.forEach(q => {
        const queryName = q.query.definitions[0].name.value;
        const newData = { ...getData(data) };
        let d = {};
        if (q.hasPagination) {
          if (newData) {
            d[queryName] = [newData];
            cache.writeQuery({
              query: q.query,
              data: d,
              variables: q.variables
            });
          }
        } else {
          const existingList = cache.readQuery({ query: q.query, variables: q.variables });
          if (existingList && existingList[queryName] && newData) {
            d[queryName] = [...existingList[queryName], newData];
            cache.writeQuery({
              query: q.query,
              data: d,
              variables: q.variables
            });
          }
        }
      });
    }
  };
}
export function getUpdateCacheOpt(queryToUpdate, variables) {
  return {
    update: (cache, { data }) => {
      try {
        const queryName = queryToUpdate.definitions[0].name.value;
        const newData = { ...getData(data) };
        let d = {};
        const existingList = cache.readQuery({ query: queryToUpdate, variables });
        if (existingList && existingList[queryName] && newData) {
          d[queryName] = [...existingList[queryName]];
          Utils.replaceArrItem(d[queryName], newData);
          cache.writeQuery({
            query: queryToUpdate,
            data: d,
            variables
          });
        }
      } catch (e) {
        console.log("cannot update cache(update)", e);
      }
    }
  };
}
export function getDeleteCacheOpt(queryToUpdate, variables, paginationEnabled, otherQuerys) {
  return {
    update: (cache, { data }) => {
      let queries = [{ query: queryToUpdate, variables, hasPagination: paginationEnabled }];
      if (otherQuerys) {
        queries.push({ query: otherQuerys });
      }
      queries.forEach(q => {
        try {
          const queryName = q.query.definitions[0].name.value;
          const existingList = cache.readQuery({ query: q.query, variables: q.variables });
          const deletedId = getData(data);
          let d = {};
          if (existingList && existingList[queryName] && deletedId) {
            if (q.hasPagination) {
              let deletedItem = existingList[queryName].find(e => e.id === deletedId);
              d[queryName] = [{ ...deletedItem, id: "DELETED_" + deletedItem.id }];
            } else {
              d[queryName] = existingList[queryName].filter(e => e.id !== deletedId);
            }
            cache.writeQuery({
              query: q.query,
              data: d,
              variables: q.variables
            });
          }
        } catch (e) {
          console.log("cannot update cache(delete)", e);
        }
      });
    }
  };
}

// current fetchMore logic may have duplicate record, remove it in merge func
function cacheMerge(existing = [], incoming, { readField }) {
  if (incoming.length == 0) {
    return existing;
  } else {
    // handle delete item in cache
    let id = readField("id", incoming[0]);
    if (id.startsWith("DELETED_")) {
      id = id.split("DELETED_")[1];
      return existing.filter(e => !e.__ref.includes(id));
    } else {
      // remove duplicated record
      let incomingMap = Utils.arrayToMap(incoming, "__ref");
      let filteredExisting = existing.filter(r => !incomingMap[r.__ref]);
      return [...filteredExisting, ...incoming];
    }
  }
}

// const apolloCache = new InMemoryCache();
//reuse cache, no need to call api again when update apolloClient, but the useQuery will still update and trigger render
const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // setting for pagination
        queryProfiles: {
          keyArgs: ["input", ["tenant", "search", "orders"]],
          merge: cacheMerge
        },
        queryAlertLog: {
          keyArgs: ["input", ["period", "timezoneOffsetMinute", "orders"]], //TODOricky will have orders field later?
          merge: cacheMerge
        }
      }
    }
  }
});
const httpLink = new createHttpLink({
  uri: `${URL}api/gql`,
  credentials: "include",
  fetchOptions: {
    timeout: 2 * 60 * 1000
  }
});
export function createApolloClient(signInMethod, idToken) {
  if (!signInMethod && !idToken) {
    // dummy client, no suppose to use
    return new ApolloClient({
      uri: `${URL}api/gql`,
      credentials: "include",
      cache: apolloCache
    });
  } else {
    const authLink = setContext(function (_, opt) {
      let tmp = {
        headers: {
          ...opt.headers,
          "x-request-id": v4(),
          "authorization-method": signInMethod
        }
      };
      if (idToken) {
        tmp.headers.authorization = "Bearer " + idToken;
      }
      return tmp;
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: apolloCache
    });
  }
}

export function getData(res, isMultiple) {
  if (res) {
    if (Object.prototype.hasOwnProperty.call(res, "data")) {
      res = res.data;
    }
    if (res) {
      let result = [];
      for (let key in res) {
        if (Object.prototype.hasOwnProperty.call(res, key) && key != "error") {
          result.push(res[key]);
        }
      }
      return isMultiple ? result : result[0];
    }
  }
}

export function getSortedData(res, sortBy1, isNum1, sortBy2, isNum2) {
  return sortData(getData(res), sortBy1, isNum1, sortBy2, isNum2);
}

function sortData(data, sortBy1, isNum1, sortBy2, isNum2) {
  return data ? data.slice().sort(Utils.getSorter(sortBy1, isNum1, sortBy2, isNum2)) : data;
}

function filterWsData(wsList, nodeType) {
  if (wsList) {
    if (nodeType === LIST_TYPE.hotdesk) {
      wsList = wsList.filter(ws => ws.isHotDesk);
    } else if (nodeType === LIST_TYPE.fixdesk) {
      wsList = wsList.filter(ws => !ws.isHotDesk);
    }
  }
  return wsList;
}

export function handle401(navigate, location, searchParams) {
  let entries = searchParams.entries();
  let path = `/redirect/${encodeURIComponent(location.pathname)}`;
  let queryStr = [];
  while (true) {
    let e = entries.next();
    if (e.done) {
      break;
    } else {
      queryStr.push(e.value[0] + "=" + e.value[1]);
    }
  }
  if (queryStr.length) {
    navigate({
      pathname: path,
      search: "?" + queryStr.join("&")
    });
  } else {
    navigate(path);
  }
}

export function useMutationErrHandler() {
  const navigate = useNavigate();
  const modal = useSimpleModal();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return function (e, msg) {
    if (!e) {
      console.error("MUST provide error object!!");
    }
    if (e?.networkError && e.networkError.statusCode && e.networkError.statusCode == 401) {
      modal.close();
      console.error("update err 401", e);
      handle401(navigate, location, searchParams);
    } else {
      console.error(e);
      if (msg) {
        modal.errMsg(msg);
      } else {
        modal.generalErrMsg();
      }
    }
  };
}

export function useErrorHandler(...resArr) {
  const navigate = useNavigate();
  const modal = useSimpleModal();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (resArr) {
    for (let i = 0; i < resArr.length; i++) {
      let res = resArr[i];
      if (!res || (Object.prototype.hasOwnProperty.call(res, "called") && !res.called) || res.loading) {
        break;
      }
      if (Object.prototype.hasOwnProperty.call(res, "error")) {
        res = res.error;
      }
      if (res) {
        if (res.networkError && res.networkError.statusCode && res.networkError.statusCode == 401) {
          console.error("read err 401", res);
          handle401(navigate, location, searchParams);
          break;
        } else if (res?.graphQLErrors?.length) {
          console.error("graphQLErrors --->", res.graphQLErrors);
          // modal.generalErrMsg();
          return res.graphQLErrors[0].message;
        } else if (res?.networkError?.result) {
          console.error("networkError --->", res.networkError);
          // modal.generalErrMsg();
          return res.networkError.result.errors[0].message;
        } else {
          // modal.generalErrMsg();
          return "unknown error";
        }
      }
    }
  }
}

//listType (LIST_TYPE)
//isControl (control/assign)
//return {data error called loading}
export function useNodes(zoneId, listType, isControl, isSort, skip) {
  //TODOricky check all usage, use displayWorkstation now
  console.log("useNodes");
  const fetchPolicy = "network-only";
  const variables = { zoneId };
  const awsListData = useQuery(availableWorkstationsQuery, {
    fetchPolicy,
    variables,
    skip: skip || !zoneId || (listType !== LIST_TYPE.fixdesk && listType !== LIST_TYPE.hotdesk) || isControl
  });
  const awsListErr = useErrorHandler(awsListData);

  const cwsListData = useQuery(controllableWorkstationsQuery, {
    fetchPolicy,
    variables,
    skip: skip || !zoneId || (listType !== LIST_TYPE.fixdesk && listType !== LIST_TYPE.hotdesk) || !isControl
  });
  const cwsListErr = useErrorHandler(cwsListData);

  const rmListData = useQuery(controllableRoomsQuery, {
    fetchPolicy,
    variables,
    skip: skip || !zoneId || listType !== LIST_TYPE.room || !isControl
  });
  const rmListErr = useErrorHandler(rmListData);

  return useMemo(() => {
    return processUseNodesData(isControl, listType, isSort, awsListData, awsListErr, cwsListData, cwsListErr, rmListData, rmListErr);
  }, [isControl, listType, isSort, awsListData, awsListErr, cwsListData, cwsListErr, rmListData, rmListErr]);
  // result.loading = zoneId && result.called && !result.data && !result.error; //for cache-and-network, only first load should show loading
}

function processUseNodesData(isControl, listType, isSorted, awsListData, awsListErr, cwsListData, cwsListErr, rmListData, rmListErr) {
  console.log("processUseNodesData");

  const awsList = getData(awsListData);
  const cwsList = getData(cwsListData);
  const rmList = getData(rmListData);
  let listData = null;

  if (listType === LIST_TYPE.room) {
    listData = isSorted && rmList ? sortData(rmList.slice()) : rmList;
  } else {
    let data = isControl ? cwsList : awsList;
    if (data) {
      data = filterWsData(data.slice(), listType);
      listData = isSorted ? sortData(data) : data;
    } else {
      listData = data;
    }
  }

  if (listType === LIST_TYPE.room) {
    return { data: listData, error: rmListErr, called: rmListData.called, loading: rmListData.loading };
  } else {
    return {
      data: listData,
      error: isControl ? cwsListErr : awsListErr,
      loading: isControl ? cwsListData.loading : awsListData.loading,
      called: isControl ? cwsListData.called : awsListData.called
    };
  }
}

export function useNode(id, listType, pollInterval, skip) {
  console.log("useNode");
  const isRm = listType === LIST_TYPE.room;
  const isWs = listType === LIST_TYPE.fixdesk || listType === LIST_TYPE.hotdesk;
  const isSkip = skip || !id || !listType;
  const fetchPolicy = "network-only";
  const wsData = useQuery(workstationQuery, { fetchPolicy, variables: { id }, pollInterval, skip: isSkip || !isWs });
  const rmData = useQuery(roomQuery, { fetchPolicy, variables: { id }, pollInterval, skip: isSkip || !isRm });
  const wsErr = useErrorHandler(wsData);
  const rmErr = useErrorHandler(rmData);

  if (isRm) {
    return {
      data: getData(rmData),
      error: rmErr,
      loading: rmData.loading,
      called: rmData.called
    };
  } else {
    return {
      data: getData(wsData),
      error: wsErr,
      loading: wsData.loading,
      called: wsData.called
    };
  }
}

export function useTurnOnNode(isRm) {
  const turnOnWs = useMutation(turnOnWorkstationMutation);
  const turnOnRm = useMutation(turnOnRoomMutation);

  return isRm ? turnOnRm : turnOnWs;
}

export function useExtendNode(isRm) {
  const extendWs = useMutation(extendWorkstationMutation);
  const extendRm = useMutation(extendRoomMutation);

  return isRm ? extendRm : extendWs;
}

export function useTurnOffNode(isRm) {
  const turnOffWs = useMutation(turnOffWorkstationMutation);
  const turnOffRm = useMutation(turnOffRoomMutation);

  return isRm ? turnOffRm : turnOffWs;
}

export function useChangeNodeCond(isRm) {
  const changeCondWs = useMutation(changeWorkstationConditionMutation);
  const changeCondRm = useMutation(changeRoomConditionMutation);

  return isRm ? changeCondRm : changeCondWs;
}

export function useToggleNode() {
  const [turnOffWs, turnOffWsData] = useMutation(turnOffWorkstationMutation);
  const [turnOffRm, turnOffRmData] = useMutation(turnOffRoomMutation);
  const [turnOnWs, turnOnWsData] = useMutation(turnOnWorkstationMutation);
  const [turnOnRm, turnOnRmData] = useMutation(turnOnRoomMutation);
  return {
    turnOffWs,
    turnOffWsData,
    turnOffRm,
    turnOffRmData,
    turnOnWs,
    turnOnWsData,
    turnOnRm,
    turnOnRmData
  };
}

export const listWidgetRightQuery = gql`
  query listSWidgetRight($widgetId: ID!) {
    listSWidgetRight(widgetId: $widgetId) {
      userId
      right
    }
  }
`;
export const transferWidgetOwnerMutation = gql`
  mutation transferSWidgetOwner($widgetId: ID!, $toUserId: ID!) {
    transferSWidgetOwner(widgetId: $widgetId, toUserId: $toUserId)
  }
`;
export const assignWidgetRightsMutation = gql`
  mutation assignSWidgetRight($widgetId: ID!, $list: [SWidgetAssignRightInputList]!) {
    assignSWidgetRight(widgetId: $widgetId, list: $list)
  }
`;
// Dashboard Service
export const getSDashboardsByUserDefault = gql`
  query getSDashboardsByUserDefault($id: ID!) {
    getSDashboardsByUserDefault(userId: $id) {
      id
      name
      tenantId
      editable
      widgets {
        id
        widgetId
        dashboardId
        name
        description
        dataSource
        type
        positionX
        positionY
        parameters
        visualType
        width
        height
        subOptions
        povs
      }
    }
  }
`;

// getDashboardIdByUserDefault
export const getDashboardIdByUserDefault = gql`
  query getSDashboardsByUserDefault($id: ID!) {
    getSDashboardsByUserDefault(userId: $id) {
      id
    }
  }
`;
// Dashboard Service
export const getSWidgetsByTenant = gql`
  query getSWidgetsByTenant($id: ID) {
    getSWidgetsByTenant(tenantId: $id) {
      id
      name
      description
      tenantId
      minWidth
      minHeight
      dataSource
      visualType
      status
      resourceUnit
      type
      parameters
      boundWithUserDefaultDashboardId
      boundWithUserDefaultDashboard
      subOptions
    }
  }
`;
const dashboardWidget = `{
    id
    widgetId
    dashboardId
    name
    description
    type
    positionX
    positionY
    visualType
    width
    height
  }
`;

frag.dashboardWidgetSimple = gql`
  fragment SDashboardWidgetSimple on SDashboardWidgetSimple{
    ...${dashboardWidget}
  }
`;

frag.dashboardWidgetMap = gql`
  fragment SDashboardWidgetMap on SDashboardWidgetMap{
    ...${dashboardWidget}

  }
`;

frag.dashboardWidgetDataBarChartCompare = gql`
  fragment SDashboardWidgetDataBarChartCompare on SDashboardWidgetDataBarChartCompare {
    dataBarChartCompare {
      category
      data {
        percent
        name
        value
        unit
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartPercent = gql`
  fragment SDashboardWidgetDataBarChartPercent on SDashboardWidgetDataBarChartPercent {
    dataBarChartPercent {
      percent
      title
      a {
        name
        value
      }
      b {
        name
        value
      }
      c {
        name
        value
      }
    }
  }
`;
frag.dashboardWidgetDataBarCharBdst = gql`
  fragment SDashboardWidgetDataBarCharBdst on SDashboardWidgetDataBarCharBdst {
    dataBarCharBdst {
      category
      data {
        value
        unit
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartBdst = gql`
  fragment SDashboardWidgetDataBarChartBdst on SDashboardWidgetDataBarChartBdst {
    dataBarChartBdst {
      category
      data {
        value
        unit
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartRanking = gql`
  fragment SDashboardWidgetDataBarChartRanking on SDashboardWidgetDataBarChartRanking {
    id
    dataBarChartRanking {
      title
      list {
        name
        value
      }
    }
  }
`;
frag.dashboardWidgetDataPieChartConsumption = gql`
  fragment SDashboardWidgetDataPieChartConsumption on SDashboardWidgetDataPieChartConsumption {
    id
    dataPieChartConsumption {
      list {
        name
        value
      }
    }
  }
`;
frag.dashboardWidgetDataHeatMap = gql`
  fragment SDashboardWidgetDataHeatMap on SDashboardWidgetDataHeatMap {
    dataHeatMap {
      categoryX
      categoryY
      data {
        data
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartWaterBreakDown = gql`
  fragment SDashboardWidgetDataBarChartWaterBreakDown on SDashboardWidgetDataBarChartWaterBreakDown {
    dataBarChartWaterBreakDown {
      categoryX
      categoryY
      data {
        data {
          name
          data
          raw
        }
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartEnergyBreakDown = gql`
  fragment SDashboardWidgetDataBarChartEnergyBreakDown on SDashboardWidgetDataBarChartEnergyBreakDown {
    dataBarChartEnergyBreakDown {
      categoryY
      data {
        data {
          name
          value
          raw
        }
      }
    }
  }
`;
frag.dashboardWidgetDataLineChart = gql`
  fragment SDashboardWidgetDataLineChart on SDashboardWidgetDataLineChart {
    dataLineChart {
      category
      data {
        name
        unit
        data
      }
    }
  }
`;
frag.dashboardWidgetDataBarChartUsage = gql`
  fragment SDashboardWidgetDataBarChartUsage on SDashboardWidgetDataBarChartUsage {
    dataBarChartUsage {
      lengend
      category
      data {
        name
        unit
        value
      }
    }
  }
`;
frag.dashboardWidgetDataTemperature = gql`
  fragment SDashboardWidgetDataTemperature on SDashboardWidgetDataTemperature {
    dataTemperature {
      categoryX
      points {
        name
        number
      }
      data {
        value
        name
        pointNumber
      }
    }
  }
`;
frag.dashboardWidgetDataBarLineComparison = gql`
  fragment SDashboardWidgetDataBarLineComparison on SDashboardWidgetDataBarLineComparison {
    dataBarLineComparison {
      category
      currentData {
        name
        value {
          value
          name
        }
      }
      previousData {
        name
        value {
          value
          name
        }
      }
    }
  }
`;
frag.simpleHierarchyNode = gql`
  fragment SimpleHierarchyNode on HierarchyNode {
    id
    type
    name
    childrenCount
    povUnit
    dataCategories
  }
`;
frag.hierarchyNodeDetail = gql`
  fragment HierarchyNodeDetail on HierarchyNode {
    ...SimpleHierarchyNode
    datapoint {
      id
      name
      dataId
      dataType
      parent {
        id
      }
    }
  }
  ${frag.simpleHierarchyNode}
`;

export const getSDashboardWidgetData = gql`
  query getSDashboardWidgetData($list: [ID]!, $period: SWidgetDataPeriod, $subOption: String, $timezoneOffset: Int) {
    getSDashboardWidgetData(list: $list, period: $period, subOption: $subOption, timezoneOffset: $timezoneOffset) {
      id
      ...SDashboardWidgetDataBarChartCompare
      ...SDashboardWidgetDataHeatMap
      ...SDashboardWidgetDataBarChartWaterBreakDown
      ...SDashboardWidgetDataLineChart
      ...SDashboardWidgetDataBarChartUsage
      ...SDashboardWidgetDataBarChartEnergyBreakDown
      ...SDashboardWidgetDataBarChartPercent
      ...SDashboardWidgetDataBarChartBdst
      ...SDashboardWidgetDataBarChartRanking
      ...SDashboardWidgetDataPieChartConsumption
      ...SDashboardWidgetDataTemperature
      ...SDashboardWidgetDataBarLineComparison
    }
  }
  ${frag.dashboardWidgetDataBarChartCompare}
  ${frag.dashboardWidgetDataHeatMap}
  ${frag.dashboardWidgetDataBarChartWaterBreakDown}
  ${frag.dashboardWidgetDataBarChartEnergyBreakDown}
  ${frag.dashboardWidgetDataLineChart}
  ${frag.dashboardWidgetDataBarChartUsage}
  ${frag.dashboardWidgetDataBarChartPercent}
  ${frag.dashboardWidgetDataBarChartBdst}
  ${frag.dashboardWidgetDataBarChartRanking}
  ${frag.dashboardWidgetDataPieChartConsumption}
  ${frag.dashboardWidgetDataTemperature}
  ${frag.dashboardWidgetDataBarLineComparison}
`;
export const resizeSDashboardWidget = gql`
  mutation resizeSDashboardWidget($list: [ResizeSDashboardWidgetInput]!) {
    resizeSDashboardWidget(list: $list)
  }
`;

export const expandHierarchyNode = gql`
  query expandHierarchyNode($id: ID!, $type: HierarchyNodeType!, $childrenCountBlacklist: [HierarchyDataPointDataType]) {
    expandHierarchyNode(input: { id: $id, type: $type }, childrenCountBlacklist: $childrenCountBlacklist) {
      root {
        ...HierarchyNodeDetail
        children {
          ...HierarchyNodeDetail
        }
      }
    }
  }
  ${frag.hierarchyNodeDetail}
`;

export const queryHierarchyNodeLocations = gql`
  query queryHierarchyNodeLocations($id: ID!, $type: HierarchyNodeType!) {
    queryHierarchyNodeLocations(input: { id: $id, type: $type }) {
      ...HierarchyNodeDetail
      children {
        ...HierarchyNodeDetail
      }
    }
  }
  ${frag.hierarchyNodeDetail}
`;
export const querySimpleHierarchyNodePath = gql`
  query querySimpleHierarchyNodePath($id: ID!, $type: HierarchyNodeType!) {
    queryHierarchyNodeLocations(input: { id: $id, type: $type }) {
      ...HierarchyNodeDetail
    }
  }
  ${frag.hierarchyNodeDetail}
`;

export const getSummaryData = gql`
  query getSummaryData(
    $povIds: [ID]
    $siteIds: [ID]
    $timePeriod: TimePeriodType!
    $source: SourceType!
    $timezoneOffsiteMinute: Int!
    $startTime: Int
    $endTime: Int
    $pellet: SummaryDataPellet
    $metricType: MetricType
  ) {
    getSummaryData(
      povIds: $povIds
      siteIds: $siteIds
      timePeriod: $timePeriod
      source: $source
      timezoneOffsiteMinute: $timezoneOffsiteMinute
      startTime: $startTime
      endTime: $endTime
      pellet: $pellet
      metricType: $metricType
    ) {
      povId
      siteId
      totalValue
      values {
        ts
        value
        number
      }
    }
  }
`;

export const getSummaryHeatmap = gql`
  query getSummaryHeatmap($povIds: [ID]!, $startTime: Int, $endTime: Int, $timePeriod: TimePeriodType!, $source: SourceType!, $timezoneOffsiteMinute: Int!) {
    getSummaryHeatmap(povIds: $povIds, startTime: $startTime, endTime: $endTime, timePeriod: $timePeriod, source: $source, timezoneOffsiteMinute: $timezoneOffsiteMinute) {
      date
      values {
        value
        number
      }
    }
  }
`;
export const getConsumption = gql`
  query getConsumption(
    $startTime: Int
    $endTime: Int
    $datapointIds: [ID]!
    $timePeriod: TimePeriodType!
    $source: SourceType!
    $timezoneOffsiteMinute: Int!
    $isVirtual: Boolean!
    $pellet: SummaryDataPellet
    $metricType: MetricType
  ) {
    getConsumption(
      datapointIds: $datapointIds
      startTime: $startTime
      endTime: $endTime
      timePeriod: $timePeriod
      source: $source
      timezoneOffsiteMinute: $timezoneOffsiteMinute
      isVirtual: $isVirtual
      pellet: $pellet
      metricType: $metricType
    ) {
      totalValue
      id
      values {
        ts
        value
        number
      }
    }
  }
`;

export const getTemperatureRawData = gql`
  query getTemperatureRawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getTemperatureRawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avgTemperature
      temperatures {
        value
        timestamp
      }
    }
  }
`;
export const getIaqTemperatureRawData = gql`
  query getIaqTemperatureRawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getIaqTemperatureRawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avg
      data {
        value
        timestamp
      }
    }
  }
`;
export const getIaqTvocRawData = gql`
  query getIaqTvocRawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getIaqTvocRawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avg
      data {
        value
        timestamp
      }
    }
  }
`;
export const getIaqHumidityRawData = gql`
  query getIaqHumidityRawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getIaqHumidityRawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avg
      data {
        value
        timestamp
      }
    }
  }
`;
export const getIaqPm25RawData = gql`
  query getIaqPm25RawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getIaqPm25RawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avg
      data {
        value
        timestamp
      }
    }
  }
`;
export const getIaqCo2RawData = gql`
  query getIaqCo2RawData($deviceIds: [ID]!, $startTime: Int, $endTime: Int, $pellet: SummaryDataPellet, $timezoneOffsiteMinute: Int!) {
    getIaqCo2RawData(deviceIds: $deviceIds, startTime: $startTime, endTime: $endTime, timezoneOffsiteMinute: $timezoneOffsiteMinute, pellet: $pellet) {
      deviceId
      avg
      data {
        value
        timestamp
      }
    }
  }
`;

export const updateSWidget = gql`
  mutation updateSWidget($id: ID!, $input: SWidgetInput!) {
    updateSWidget(id: $id, input: $input) {
      id
      description
    }
  }
`;

export const createSWidget = gql`
  mutation createSWidget($id: ID, $input: SWidgetInput!) {
    createSWidget(dashboardId: $id, input: $input) {
      id
    }
  }
`;

export const deleteSWidget = gql`
  mutation deleteSWidget($id: ID!) {
    deleteSWidget(id: $id)
  }
`;

export const addSDashboardWidget = gql`
  mutation addSDashboardWidget($input: AddSDashboardWidgetInput!) {
    addSDashboardWidget(input: $input)
  }
`;

export const deleteSDashboardWidget = gql`
  mutation deleteSDashboardWidget($id: ID!) {
    deleteSDashboardWidget(id: $id)
  }
`;

export function commonSDashboardWidgetData(params) {
  let newParams = { ...params, timezoneOffset: Math.abs(new Date().getTimezoneOffset()) };
  if (params) {
    const profileInterval = 600000; // auto refresh
    const DashboardWidgetDataQuery = useQuery(getSDashboardWidgetData, {
      variables: newParams,
      pollInterval: profileInterval,
      skip: !params?.list?.length,
      fetchPolicy: "no-cache"
    });
    return DashboardWidgetDataQuery;
  }
}

export function dashboardWidgetDataQueryRefetch(DashboardWidgetDataQuery) {
  const { error, loading } = DashboardWidgetDataQuery;
  if (!loading) {
    DashboardWidgetDataQuery.refetch();
  }
}
